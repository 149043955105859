
<template>
  <v-dialog :value="value" class="dialog" width="1200">
    <v-card color=#fafafa>
      <v-toolbar flat dark color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{!isBulkEdit ? `Edit - ${item.OrderCode} - ${item.Description}` : `Edit ${selected.length} Items`}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row v-if="!isBulkEdit" dense>
          <v-col cols="2">
            <v-text-field readonly dense background-color="#fff" outlined label="Item Code" v-model="item.OrderCode"
              autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field readonly dense background-color="#fff" outlined label="Pack" v-model="item.Pack"
              autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field readonly dense background-color="#fff" outlined label="Size" v-model="item.Size"
              autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field readonly dense background-color="#fff" outlined label="UPC" v-model="item.UPC" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field readonly dense background-color="#fff" outlined label="Item Description"
              v-model="item.Description" autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined class="input" placeholder="0.00" type="number"
              step="0.01" min="0" prefix="$" label="Case Cost" v-model="item.CaseCost" @change="formatCurrencyInput('CaseCost', item.CaseCost)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01" min="0"
              outlined label="AMAP" v-model="item.AMAP" @change="formatCurrencyInput('AMAP', item.AMAP)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="Ad BB" v-model="item.AdBB" @change="formatCurrencyInput('AdBB', item.AdBB)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="TPR BB" v-model="item.TPRBB" @change="formatCurrencyInput('TPRBB', item.TPRBB)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="Ad Scan" v-model="item.AdScan" @change="formatCurrencyInput('AdScan', item.AdScan)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="TPR Scan" v-model="item.TPRScan" @change="formatCurrencyInput('TPRScan', item.TPRScan)" autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="ePay Ad" v-model="item.ePayAd" @change="formatCurrencyInput('ePayAd', item.ePayAd)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="ePay TPR" v-model="item.ePayTPR" @change="formatCurrencyInput('ePayTPR', item.ePayTPR)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="EBA" v-model="item.EBA" @change="formatCurrencyInput('EBA', item.EBA)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" max="1" outlined label="Compete" v-model="item.Compete" @change="formatCurrencyInput('Compete', item.Compete)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$"
              type="number" step="0.01" min="0" outlined label="PCAF" v-model="item.PCAF" @change="formatCurrencyInput('PCAF', item.PCAF)" autocomplete="off">
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined label="Deal ID" v-model="item.DealID"
              autocomplete="off">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-toolbar color="#fafafa" flat>
        <v-spacer></v-spacer>
        <v-col class="btn-group" align="right" cols="5">
          <v-btn v-if="!isBulkEdit" height="40" color="#d32f2f" class="white--text"
            @click="confirmDialog=true">
            Delete<v-icon right>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn height="40" color="#00B24A" class="ml-2 white--text" @click="isBulkEdit ? bulkUpdate() : updateItem(item)">
            Update<v-icon right>mdi-update</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>
    </v-card>

    <ConfirmDialog
      :remove="remove"
      :action="'Delete'"
      :type="'Item'"
      :value.sync="confirmDialog"
    />

  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'ContractItemEdit',

  mixins: [notification],

  props: ['value', 'selectedItem', 'isBulkEdit', 'selected', 'contract', 'getContract'],

  components: {
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  data () {
    return {
      action: '',
      confirmDialog: false
    }
  },

  computed: {
    item () {
      return cloneDeep(this.selectedItem)
    },

    selectedItems () {
      return cloneDeep(this.selected)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value && !this.isBulkEdit) {
          for (const key in this.item.Item) {
            this.item[key] = this.item.Item[key]
          }
          const valuesToFormat = {
            AMAP: this.item.AMAP,
            AdBB: this.item.AdBB,
            AdScan: this.item.AdScan,
            Compete: this.item.Compete,
            EBA: this.item.EBA,
            PCAF: this.item.PCAF,
            TPRBB: this.item.TPRBB,
            TPRScan: this.item.TPRScan,
            ePayAd: this.item.ePayAd,
            ePayTPR: this.item.ePayTPR,
            CaseCost: this.item.CaseCost
          }
          for (const key in valuesToFormat) {
            this.formatCurrencyInput(key, valuesToFormat[key])
          }
        }
      }
    }
  },

  methods: {
    formatCurrencyInput (key, value) {
      this.item[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    async updateItem () {
      this.item.Item.CaseCost = this.item.CaseCost
      this.item.Item.Description = this.item.Description
      this.item.Item.ItemCode = this.item.ItemCode
      this.item.Item.OrderCode = this.item.OrderCode
      this.item.Item.Pack = this.item.Pack
      this.item.Item.PromoGroup = this.item.PromoGroup
      this.item.Item.Size = this.item.Size
      this.item.Item.UPC = this.item.UPC

      delete this.item.CaseCost
      delete this.item.Description
      delete this.item.ItemCode
      delete this.item.OrderCode
      delete this.item.Pack
      delete this.item.PromoGroup
      delete this.item.Size
      delete this.item.UPC

      await this.$contracts.updateItem(this.item)
        .then(() => {
          this.notify('success', 'Item Successfully Updated')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Item Not Updated')
        })
      this.getContract()
    },

    async bulkUpdate () {
      const promises = []
      const errors = []

      this.showLoader('Updating')
      this.selectedItems.forEach(item => {
        for (const key in this.item) {
          if (!Object.prototype.hasOwnProperty.call(key, item) && key !== 'CaseCost') {
            item[key] = this.item[key]
          } else {
            item.Item.CaseCost = this.item[key]
          }
        }

        promises.push(
          this.$contracts.updateItem(item)
            .then(() => {
            }).catch(() => {
              errors.push(`${item.Item.OrderCode} - ${item.Item.Description} Not Updated`)
            })
        )
      })

      await Promise.allSettled(promises)
      if (errors.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', `${this.selected.length} Items Updated`)
        this.$emit('update:value', false)
      }
      this.getContract(this.contract.ContractID)
    },

    async remove () {
      await this.$contracts.deleteItem(this.item)
        .then(() => {
          this.notify('success', 'Item Successfully Deleted')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Item Not Deleted')
        })
      this.getContract()
    }

  }
}
</script>
